<template>
    <div class="conlist">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <p>
                        <span>申请状态</span>
                        <el-radio-group
                            size="small"
                            v-model="searchVal.statusVal"
                        >
                            <el-radio-button
                                label="待审批"
                                @click.native.prevent="clickStatus('待审批')"
                            ></el-radio-button>
                            <el-radio-button
                                label="已通过"
                                @click.native.prevent="clickStatus('已通过')"
                            ></el-radio-button>
                            <el-radio-button
                                label="已开票"
                                @click.native.prevent="clickStatus('已开票')"
                            ></el-radio-button>
                            <el-radio-button
                                label="已驳回"
                                @click.native.prevent="clickStatus('已驳回')"
                            ></el-radio-button>
                            <el-radio-button
                                label="已作废"
                                @click.native.prevent="clickStatus('已作废')"
                            ></el-radio-button>
                            <el-radio-button
                                label="异常"
                                @click.native.prevent="clickStatus('异常')"
                            ></el-radio-button>
                        </el-radio-group>
                    </p>
                    <p>
                        <Department
                            ref="department"
                            :departmentWidth="170"
                            :adminWidth="170"
                            :depSearch="btnP.depSearch"
                            :searchUser="btnP.searchUser"
                            @searchData="searchData"
                        ></Department>
                    </p>
                    <!-- <p>
                        <span v-show="btnP.depSearch">部门</span>
                        <el-select
                            v-show="btnP.depSearch"
                            v-model="searchVal.dempVal"
                            size="small"
                            style="width: 170px"
                            clearable
                            placeholder="请选择"
                            @change="
                                (currentPage = 1),
                                    (pagesize = 50),
                                    changeDepartmentId()
                            "
                        >
                            <el-option
                                v-for="item in dempData"
                                :key="item.id"
                                :label="item.departmentName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p>
                    <p>
                        <span v-show="btnP.searchUser">成员</span>
                        <el-select
                            v-show="btnP.searchUser"
                            v-model="searchVal.adminVal"
                            size="small"
                            style="width: 170px"
                            clearable
                            placeholder="请选择"
                            @change="
                                (currentPage = 1),
                                    (pagesize = 50),
                                    getTableData()
                            "
                        >
                            <el-option
                                v-for="item in userData"
                                :key="item.id"
                                :label="item.nickName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p> -->
                    <p>
                        <span>查询</span>

                        <el-input
                            size="small"
                            style="width: 170px"
                            placeholder="合同编号、客户名称"
                            v-model="searchVal.fieldVal"
                            clearable
                        >
                        </el-input>
                        <el-button
                            @click="
                                (currentPage = 1),
                                    (pagesize = 50),
                                    getTableData()
                            "
                            type="primary"
                            size="small"
                            icon="el-icon-search"
                        ></el-button>
                    </p>
                </div>
                <div style="margin-bottom: 10px; width: 160px">
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="
                            (currentPage = 1), (pagesize = 50), getTableData()
                        "
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>发票列表</p>
            <span>
                <!--  -->
                <button @click="onAddData()" v-show="btnP.add" class="cancel">
                    <i class="el-icon-plus" style="font-size: 12px"></i>
                    申请发票
                </button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <!-- <el-table-column label="申请时间" prop="createTime" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span :class="scope.row.contractStatus == 3 ? 'isRed' : ''">{{
                        scope.row.createTime ? scope.row.createTime.slice(0, 10) : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column> -->
            <el-table-column
                label="申请编号"
                prop="createTime"
                show-overflow-tooltip
                width="120"
            >
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{
                            scope.row.invoiceNo ? scope.row.invoiceNo : '- -'
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="合同编号" width="135" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.contractStatus != 3"
                        type="text"
                        style="color: #2370eb"
                        @click="onDetails(scope.row)"
                        >{{ scope.row.contractNo }}</el-button
                    >
                    <span class="isRed" v-if="scope.row.contractStatus == 3">{{
                        scope.row.contractNo
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="客户名称"
                prop="companyName"
                show-overflow-tooltip
                width="239"
            >
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{ scope.row.companyName }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="合同金额"
                width="80"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{ scope.row.contractAmount }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="开票金额"
                width="80"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{ scope.row.invoiceAmount }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="开户名称"
                width="239"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{ scope.row.invoiceName }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="开票类别" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{
                            scope.row.invoiceCategory == 1
                                ? '电子发票'
                                : scope.row.invoiceCategory == 2
                                ? '纸质发票'
                                : '- -'
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="开票类型" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{
                            scope.row.invoiceType == 1
                                ? '普通发票'
                                : scope.row.invoiceType == 2
                                ? '增值税专用发票'
                                : '- -'
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="申请状态" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{
                            scope.row.status == 1
                                ? '待审批'
                                : scope.row.status == 2
                                ? '已通过'
                                : scope.row.status == 3
                                ? '已驳回'
                                : scope.row.status == 4
                                ? '已撤销'
                                : scope.row.status == 5
                                ? '已开票'
                                : scope.row.status == 6
                                ? '已作废'
                                : '- -'
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="所属人"
                prop="adminName"
                width="55"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{ scope.row.adminName }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="收件人"
                width="55"
                prop="receiveName"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{ scope.row.receiveName }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="签署公司" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                        >{{ scope.row.contractB }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <!-- <el-table-column
                label="快递信息"
                prop="expressNo"
                width="140"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-button
                        v-show="scope.row.contractStatus != 3"
                        type="text"
                        v-if="scope.row.status == 2"
                        style="color: #2370eb"
                        @click="onAddExpress(scope.row)"
                    >
                        <span
                            v-show="scope.row.expressNo"
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(scope.row)"
                            class="iconfont icon-fuzhi1"
                        ></span
                        >{{
                            scope.row.expressNo ? scope.row.expressNo : '添加'
                        }}</el-button
                    >

                    <span
                        v-show="scope.row.contractStatus == 3"
                        :class="scope.row.contractStatus == 3 ? 'isRed' : ''"
                    >
                        {{ scope.row.expressNo }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column> -->
            <el-table-column label="操作" fixed="right" width="160">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        v-if="
                            btnP.revoke &&
                            scope.row.status == 1 &&
                            scope.row.contractStatus != 3
                        "
                        style="color: #2370eb"
                        @click="onRevoke(scope.row)"
                        >撤销</el-button
                    >
                    <el-button
                        type="text"
                        v-if="btnP.void && scope.row.status == 2"
                        style="color: #2370eb"
                        @click="onVoid(scope.row)"
                        >作废
                    </el-button>
                    <el-button
                        type="text"
                        v-if="
                            btnP.upData &&
                            (scope.row.status == 3 || scope.row.status == 4) &&
                            scope.row.contractStatus != 3
                        "
                        style="color: #2370eb"
                        @click="onUpData(scope.row)"
                        >重新申请
                    </el-button>
                    <el-button
                        type="text"
                        v-if="
                            btnP.audit &&
                            scope.row.status == 1 &&
                            scope.row.contractStatus != 3
                        "
                        style="color: #2370eb"
                        @click="onApproval(scope.row)"
                        >审批</el-button
                    >
                    <el-button
                        type="text"
                        v-if="
                            !scope.row.contractNo &&
                            btnP.relation &&
                            (scope.row.status == 2 || scope.row.status == 5)
                        "
                        style="color: #2370eb"
                        @click="onRelation(scope.row)"
                        >关联合同</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box" v-if="isPage">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 撤销-->
        <el-dialog
            :visible.sync="drawerRevok"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    撤销
                </div>
            </template>
            <div class="content">是否撤销该发票？</div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >确定</el-button
                >
            </div>
        </el-dialog>
        <!-- 审批 -->
        <el-drawer
            :visible.sync="drawerApproval"
            :direction="direction"
            :before-close="handleApproval"
            size="720px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    审批发票
                </div>
            </template>
            <Approval @close="handleApproval" ref="approval" />
        </el-drawer>
        <!-- 快递信息-->
        <el-dialog
            :visible.sync="drawerExpress"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    快递信息
                </div>
            </template>

            <Express ref="express" @close="handleClose" />
        </el-dialog>
        <!-- 关联合同 -->
        <el-drawer
            :visible.sync="drawerRelation"
            :direction="direction"
            :before-close="handleRelation"
            size="520px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    关联合同
                </div>
            </template>
            <Relation @close="handleRelation" ref="relation" />
        </el-drawer>
    </div>
</template>

<script>
import { invoiceList, invoiceUpdate } from '@/api/contr/invoice.js';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import Approval from './approval/approval.vue';
import Express from './express.vue';
import Relation from './relation.vue';
import Department from '../../../components/Department';
export default {
    components: {
        Approval,
        Express,
        Relation,
        Department,
    },
    data() {
        return {
            btnP: {},
            data: '',
            searchVal: {
                statusVal: '',
            },
            dempData: [],
            userData: [],
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerRevok: false,
            drawerApproval: false,
            drawerExpress: false,
            drawerRelation: false,
            direction: 'rtl',
            isPage: false,
            pageData: {},
        };
    },
    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            if (btn.searchUser) {
            }
            if (btn.searchUser && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (btn.depSearch) {
                this.getDempData();
            }
            this.getTableData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                this.$refs.department.getData(this.btnP, res.data);
            });
        },

        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.dempVal = data.departmentId;
            this.searchVal.adminVal = data.adminId;
            this.getTableData();
        },
        getTableData() {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };

            if (!this.btnP.depSearch && this.btnP.searchUser) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                if (this.searchVal.adminVal) {
                    data.param.adminId = this.searchVal.adminVal;
                }
            }
            if (this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.departmentId = this.searchVal.dempVal;
            }
            if (this.btnP.depSearch && this.btnP.searchUser) {
                data.param.departmentId = this.searchVal.dempVal;
                data.param.adminId = this.searchVal.adminVal;
            }
            if (!this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (this.searchVal.fieldVal && this.searchVal.fieldVal != '') {
                data.param.name = this.searchVal.fieldVal;
            }
            if (this.searchVal.statusVal == '待审批') {
                data.param.status = 1;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (this.searchVal.statusVal == '已通过') {
                data.param.status = 2;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (this.searchVal.statusVal == '已驳回') {
                data.param.status = 3;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (this.searchVal.statusVal == '异常') {
                data.param.contractStatus = 3;
            } else if (this.searchVal.statusVal == '已开票') {
                data.param.status = 5;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (this.searchVal.statusVal == '已作废') {
                data.param.status = 6;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            }
            this.pageData = data;
            invoiceList(data).then((res) => {
                // console.log(res);
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },
        clickStatus(e) {
            e === this.searchVal.statusVal
                ? (this.searchVal.statusVal = '')
                : (this.searchVal.statusVal = e);
            this.getTableData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        // 新增
        onAddData() {
            this.$router.push('/addApplyinvoice');
        },
        // 撤销
        onRevoke(row) {
            this.data = row;
            this.drawerRevok = true;
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                    status: 4,
                },
            };
            invoiceUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('已撤销');
                    this.handleClose();
                }
            });
        },
        onVoid(row) {
            this.$confirm(
                '即将作废当前发票申请，并通知申请人员，是否确认？',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    let data = {
                        param: {
                            id: row.id,
                            status: 6,
                        },
                    };
                    invoiceUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                            this.handleClose();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消作废',
                    });
                });
        },
        handleClose() {
            this.drawerRevok = false;
            this.drawerExpress = false;
            this.getTableData();
        },
        // 编辑
        onUpData(row) {
            console.log(row);
            this.$router.push({
                path: '/addApplyinvoice',
                query: {
                    id: row.id,
                    companyName: row.companyName,
                    customerId: row.customerId,
                },
            });
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
        },
        // 审批
        onApproval(row) {
            console.log(row);
            this.drawerApproval = true;
            setTimeout(() => {
                this.$refs.approval.getData(row);
            });
        },
        handleApproval() {
            this.drawerApproval = false;
            this.getTableData();
        },
        // 添加快递
        onAddExpress(row) {
            this.drawerExpress = true;
            setTimeout(() => {
                this.$refs.express.getData(row);
            });
        },
        onDetails(row) {
            // console.log(row);
            this.$router.push({
                path: '/condetails',
                query: {
                    id: row.contractId,
                    index: 3,
                },
            });
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
        },
        onRelation(row) {
            this.drawerRelation = true;
            setTimeout(() => {
                this.$refs.relation.getData(row);
            });
        },
        handleRelation() {
            this.drawerRelation = false;
            this.getTableData();
        },
        // 复制
        onCopy(item) {
            let url = item.expressNo;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        searchEmpty() {
            this.searchVal = {
                statusVal: '',
            };
            this.getTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
.conlist {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        padding: 10px 0 0 0;
        background: #fff;
        border-radius: 4px;
        font-size: 13px;
        .search-one {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                display: flex;
                flex-flow: wrap;
                align-items: center;
                p {
                    height: 100%;

                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    margin-right: 16px;
                    margin-top: 0;
                }
            }
        }
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        button {
            background: #2370eb;
            border-radius: 2px;
            margin-right: 16px;
        }
    }

    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.content {
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 16px;
    color: #333;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.isRed {
    color: red;
}
</style>
