<template>
    <div class="relation">
        <div style="flex: 1">
            <el-form
                label-width="75px"
                label-position="left"
                style="margin-left: 40px; margin-top: 20px"
                size="small"
                ref="form"
            >
                <el-form-item label="客户名称">
                    <span>{{ row.companyName }}</span>
                </el-form-item>
                <el-form-item label="关联合同" required>
                    <el-select
                        filterable
                        v-model="contracId"
                        style="width: 240px; margin-right: 27px"
                        clearable
                        placeholder="请选择"
                        @change="getContracData(contracId)"
                    >
                        <el-option
                            v-for="item in contractData"
                            :key="item.id"
                            :label="item.contractNo"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="contracData" v-if="data && data.companyName">
                <div class="title">
                    <p>
                        <span>合同金额</span>
                        <span>{{ data.contractAmount }}</span>
                    </p>
                    <p>
                        <span>可开票金额</span>
                        <span>{{
                            getInvoiceAmount(data.contractInvoiceBOList)
                        }}</span>
                    </p>
                </div>
                <div class="content">
                    <p style="color: #666666">合同标的</p>
                    <div>
                        <p v-for="item in data.contractTargetBOList">
                            <span>腾讯企业邮箱 </span>
                            <span v-if="item.number && item.number > 0"
                                >{{ item.number }}用户</span
                            >
                            <span v-if="item.term && item.term > 0"
                                >{{ item.term }}年</span
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { contractList, contracGet } from '@/api/contr/contr.js';
import { invoiceUpdate } from '@/api/contr/invoice.js';
export default {
    data() {
        return {
            row: {},
            data: {},
            contractData: [],
            contracId: '',
            invoiceAmount: 0,
        };
    },
    methods: {
        getData(row) {
            this.row = row;
            this.getContractList();
        },
        getContractList() {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    customerId: this.row.customerId,
                },
                pageNum: 0,
                pageSize: 0,
            };
            data.param.statusList = [12, 8, 7, 11, 10];
            contractList(data).then((res) => {
                if (res.code == 200) {
                    this.contractData = res.data.list;
                }
            });
        },
        getContracData(id) {
            if (id) {
                let data = {
                    param: {
                        id,
                    },
                };
                contracGet(data).then((res) => {
                    this.data = res.data;
                    console.log(this.data);
                });
            } else {
                this.data = {};
            }
        },
        getInvoiceAmount(data) {
            let amount = 0;
            if (data && data.length > 0) {
                data.forEach((item) => {
                    if (item.status == 1 || item.status == 2) {
                        amount += item.invoiceAmount;
                    }
                });
            }
            this.invoiceAmount =
                Number(this.data.contractAmount) - Number(amount);
            return this.invoiceAmount;
        },
        onSubmit() {
            if (!this.contracId) {
                return this.$message.error('请选择关联合同');
            }
            if (this.row.invoiceAmount > this.invoiceAmount) {
                return this.$message.error('金额不一致，关联失败');
            }
            if (
                this.row.contractB != this.data.contractB ||
                this.row.typeB != this.data.typeB
            ) {
                return this.$message.error(
                    '关联失败，合同与发票签署公司不一致'
                );
            }
            let data = {
                param: {
                    id: this.row.id,
                    contractId: this.contracId,
                },
            };
            invoiceUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('关联成功');
                    this.contracId = '';
                    this.data = {};
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.relation {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.contracData {
    width: 415px;
    background: #dfeafc;
    border-radius: 8px;
    margin-left: 40px;
    padding: 0 12px;
    .title {
        display: flex;
        padding: 16px 0;
        p {
            flex: 1;
            color: #333333;
            font-size: 14px;
            span:nth-child(1) {
                color: #666666;
                margin-right: 30px;
            }
        }
    }
    .content {
        font-size: 14px;
        display: flex;
        div {
            color: #333;
            margin-left: 30px;
            p {
                span:nth-child(1) {
                    display: inline-block;
                    width: 90px;
                }
                span {
                    margin-right: 30px;
                }
                margin-bottom: 12px;
            }
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
