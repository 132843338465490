<template>
    <div class="express">
        <div class="form-box">
            <el-form
                label-width="90px"
                label-position="left"
                style="padding-left:79px"
                size="small"
            >
                <el-form-item label="快递单号">
                    <el-input
                        v-model="expressNo"
                        placeholder="请输入"
                        style="width:240px;margin-right:27px"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { invoiceUpdate } from '@/api/contr/invoice.js';
export default {
    data() {
        return {
            expressNo: '',
            data: {},
        };
    },
    methods: {
        getData(data) {
            this.data = data;
            this.expressNo = data.expressNo;
        },
        onSubmit() {
            if (!this.expressNo) {
                return this.$message.error('请输入快递单号');
            }
            let data = {
                param: {
                    id: this.data.id,
                    expressNo: this.expressNo,
                },
            };
            invoiceUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('编辑成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.express {
    width: 100%;
    .form-box {
        height: 210px;
        padding-top: 34px;
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>
