<template>
    <div class="approval">
        <div class="user_box">
            <p class="title">
                <span></span>
                {{ data.adminName }}提交的申请
            </p>
            <el-form label-width="90px" label-position="left" size="small">
                <div class="user_content">
                    <div class="column">
                        <el-form-item label="所在部门">
                            <el-tooltip placement="top">
                                <div slot="content">
                                    <p
                                        style="line-height: 2"
                                        v-for="item in data.departmentAdminDOList"
                                        :key="item.id"
                                    >
                                        {{ item.departmentName }}
                                    </p>
                                </div>
                                <span class="tetx">
                                    {{ returnName(data) }}
                                </span>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item label="申请时间">
                            <span class="tetx">
                                {{ data.createTime }}
                            </span>
                        </el-form-item>
                        <el-form-item label="申请状态">
                            <span class="tetx"> 待审批 </span>
                        </el-form-item>
                    </div>
                    <div class="column">
                        <el-form-item label="合同编号">
                            <span class="tetx">
                                {{ data.contractNo }}
                            </span>
                        </el-form-item>
                        <el-form-item label="客户名称">
                            <span class="tetx">
                                {{ data.companyName }}
                            </span>
                        </el-form-item>
                        <el-form-item label="合同金额">
                            <span class="tetx">
                                {{ data.contractAmount }}
                            </span>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="invoice_box">
            <p class="title">
                <span></span>
                发票信息
            </p>

            <el-form label-width="90px" label-position="left" size="small">
                <div style="display: flex; padding-left: 26px">
                    <div class="column">
                        <el-form-item label="开票金额">
                            <span class="tetx">
                                {{ data.invoiceAmount }}
                            </span>
                            <span
                                @click.stop="onCopy(data.invoiceAmount)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item label="开票类别">
                            <span class="tetx">
                                {{
                                    data.invoiceCategory == 1
                                        ? '电子发票'
                                        : data.invoiceCategory == 2
                                        ? '纸质发票'
                                        : '- -'
                                }}
                            </span>
                        </el-form-item>
                        <el-form-item label="开票类型">
                            <span class="tetx">
                                {{
                                    data.invoiceType == 1
                                        ? '普通发票'
                                        : data.invoiceType == 2
                                        ? '增值税专用发票'
                                        : '- -'
                                }}
                            </span>
                        </el-form-item>
                        <el-form-item label="开票内容">
                            <span class="tetx"> 信息技术服务费 </span>
                        </el-form-item>
                        <el-form-item label="收件人" v-show="data.receiveName">
                            <span class="tetx">
                                {{
                                    data.receiveName ? data.receiveName : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.receiveName)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item
                            label="收件电话"
                            v-show="data.receivePhone"
                        >
                            <span class="tetx">
                                {{
                                    data.receivePhone
                                        ? data.receivePhone
                                        : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.receivePhone)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item
                            label="收件地址"
                            v-show="data.receiveAddress"
                        >
                            <span class="tetx">
                                {{
                                    data.receiveAddress
                                        ? data.receiveAddress
                                        : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.receiveAddress)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item
                            label="收件邮箱"
                            v-show="data.receiveEmail"
                        >
                            <span class="tetx">
                                {{
                                    data.receiveEmail
                                        ? data.receiveEmail
                                        : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.receiveEmail)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                    </div>
                    <div class="column">
                        <el-form-item label="开票名称">
                            <span class="tetx">
                                {{
                                    data.invoiceName ? data.invoiceName : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.invoiceName)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item label="税号">
                            <span class="tetx">
                                {{ data.dutyNo ? data.dutyNo : '- -' }}
                            </span>
                            <span
                                @click.stop="onCopy(data.dutyNo)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item
                            label="注册地址"
                            v-show="data.invoiceType == 2"
                        >
                            <span class="tetx">
                                {{
                                    data.registerAddress
                                        ? data.registerAddress
                                        : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.registerAddress)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item
                            label="公司电话"
                            v-show="data.invoiceType == 2"
                        >
                            <span class="tetx">
                                {{
                                    data.companyPhone
                                        ? data.companyPhone
                                        : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.companyPhone)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item
                            label="银行账户"
                            v-show="data.invoiceType == 2"
                        >
                            <span class="tetx">
                                {{
                                    data.depositBank ? data.depositBank : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.depositBank)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item
                            label="银行账号"
                            v-show="data.invoiceType == 2"
                        >
                            <span class="tetx">
                                {{
                                    data.bankAccount ? data.bankAccount : '- -'
                                }}
                            </span>
                            <span
                                @click.stop="onCopy(data.bankAccount)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item label="签署公司">
                            <span class="tetx">
                                {{ data.contractB ? data.contractB : '- -' }}
                            </span>
                            <span
                                @click.stop="onCopy(data.contractB)"
                                class="iconfont icon-fuzhi"
                                style="
                                    color: #006df3;
                                    cursor: pointer;
                                    margin-left: 10px;
                                "
                            >
                            </span>
                        </el-form-item>
                        <el-form-item label="备注">
                            <span class="tetx">
                                {{ data.remark ? data.remark : '- -' }}
                            </span>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="operation_box">
            <p class="title">
                <span></span>
                审批
            </p>

            <el-form
                label-width="90px"
                label-position="left"
                style="padding-left: 22px"
                size="small"
            >
                <el-form-item label="审批结果" required>
                    <el-radio v-model="status" :label="1">通过</el-radio>
                    <el-radio v-model="status" :label="0">驳回</el-radio>
                </el-form-item>
                <el-form-item label="审批备注">
                    <el-input
                        type="textarea"
                        style="width: 500px"
                        :rows="4"
                        placeholder="请输入（通过/驳回理由）"
                        v-model="remark"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <span style="margin-left: auto">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 20px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >保存</el-button
                ></span
            >
        </div>
    </div>
</template>
<script>
import { invoiceGet, invoiceAudit } from '@/api/contr/invoice.js';
export default {
    data() {
        return {
            data: {},
            status: 2,
            remark: '',
        };
    },
    methods: {
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        getData(data) {
            console.log(data);
            let obj = {
                param: {
                    id: data.id,
                },
            };
            invoiceGet(obj).then((res) => {
                // console.log(res);
                this.data = res.data;
            });
        },
        onSubmit() {
            if (this.status == 2) {
                return this.$message.error('请选择审批结果');
            }

            let data = {
                param: {
                    invoiceId: this.data.id,
                    remark: this.remark,
                    status: this.status,
                },
            };
            invoiceAudit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('审批成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.remark = '';
            this.status = 1;
            this.$emit('close');
        }, // 复制
        onCopy(item) {
            let url = item;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
.approval {
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    display: flex;
    overflow: auto;
    flex-direction: column;
    .title {
        display: flex;
        align-items: center;
        padding: 12px 0 16px 0;
        font-size: 14px;
        color: #333333;
        font-weight: 500;
        span {
            display: inline-block;
            width: 2px;
            height: 16px;
            background: #2370eb;
            border-radius: 1px 1px 0px 0px;
            margin-right: 22px;
        }
    }
    .user_box {
        background: #fff;
    }
    .user_content {
        display: flex;
        padding-left: 26px;
    }
    .column {
        width: 50%;
        .tetx {
            color: #333;
            word-wrap: break-word;
            word-break: normal;
        }
    }
    .invoice_box {
        background: #fff;
        margin-top: 8px;
    }
    .operation_box {
        background: #fff;
        margin-top: 8px;
        flex: 1;
    }
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e5e5;
    button {
        width: 90px;
    }
}
</style>
<style>
.approval .column .el-form-item__label {
    color: #666 !important;
}
.approval .column .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 5px !important;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #006df3;
    background: #006df3;
}
.el-radio__input.is-checked + .el-radio__label {
    color: #666;
}
</style>
